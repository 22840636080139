require('../css/app/app.scss');

const $ = require('jquery');
require('bootstrap');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css')
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js')
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');


$(document).ready(function() {
    $(".fancybox").fancybox({
        animationEffect: false
    });
    $( '#cookies-close' ).on( 'touchstart click', function(e) {
        e.preventDefault();
        $( '.row--flash--cookies' ).hide();

        var jqxhr = $.ajax( "/flash-cookies-closed" )
            .done(function() {
        })
    });
});